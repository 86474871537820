import { AddressFieldConfiguration } from 'types/models'

import { AddressField } from './address'

export const addressFieldConfig: Array<AddressFieldConfiguration> = [
  {
    field: AddressField.Country,
  },
  {
    field: AddressField.Name,
    required: true,
  },
  {
    field: AddressField.Line1,
    required: true,
  },
  {
    field: AddressField.Line2,
  },
  {
    field: AddressField.PostalCode,
    required: true,
  },
  {
    field: AddressField.City,
    required: true,
  },
]

export const CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY = 'checkout_load_time_tracking_details'

export const SINGLE_CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY =
  'single_checkout_load_time_tracking_details'

export const CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY =
  'credit_card_add_modal_load_time_tracking_details'

export const PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY =
  'payment_options_load_time_tracking_details'
