'use client'

import { ReactElement } from 'react'
import { Cell, Text, Spacer } from '@vinted/web-ui'

import PayInMethodIcon from 'components/PayInMethodIcon'

import useTranslate from 'hooks/useTranslate'
import { CreditCardModel } from 'types/models'
import { getDisplayedCardBrandName } from 'libs/utils/card'

import { ClickableCreditCardItemType } from './types'

const formatMonth = (month: number) => {
  return month.toString().padStart(2, '0')
}

type Props = {
  text?: string
  card: CreditCardModel
  suffix?: ReactElement
  clickableType: ClickableCreditCardItemType
  onClick: (card: CreditCardModel) => void
  styling?: ComponentProps<typeof Cell>['styling']
}

const CreditCard = ({ text, card, suffix, onClick, clickableType, styling }: Props) => {
  const translate = useTranslate('payments.card')

  const isClickable = clickableType !== ClickableCreditCardItemType.NotClickable
  const isDisabled =
    card.expired && clickableType === ClickableCreditCardItemType.NotExpiredClickable

  const handleClick = () => {
    if (isClickable) onClick(card)
  }

  const renderTitle = () => {
    return (
      <>
        <Text text={getDisplayedCardBrandName(card.brand)} bold theme="amplified" />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <Text text={text || translate('ends_with')} />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <Text text={card.lastFour} bold theme="amplified" />
      </>
    )
  }

  const renderBody = () => {
    const { expired, expirationYear, expirationMonth } = card

    if (!expired && !expirationMonth && !expirationYear) return null

    const expirationDateMessage = () => {
      if (!expirationMonth || !expirationYear) return null

      const expirationDate = `${formatMonth(expirationMonth)}/${expirationYear}`

      return (
        <>
          {translate('expires')}
          <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
          {expirationDate}
        </>
      )
    }

    return (
      <Text
        text={expired ? translate('expired') : expirationDateMessage()}
        theme={expired ? 'warning' : undefined}
        type={Text.Type.Subtitle}
      />
    )
  }

  return (
    <Cell
      styling={styling || Cell.Styling.Default}
      disabled={isDisabled}
      prefix={<PayInMethodIcon payInMethodBrand={card.brand} />}
      type={isClickable ? Cell.Type.Navigating : undefined}
      suffix={suffix}
      title={renderTitle()}
      body={renderBody()}
      onClick={handleClick}
      testId="credit-card-list--card-cell"
    />
  )
}

export default CreditCard
