'use client'

import { useEffect, FocusEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { InputText } from '@vinted/web-ui'

import { renderValidation } from 'components/Input'

import useTranslate from 'hooks/useTranslate'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { FULL_NAME } from 'constants/regexes'

import { FormModel, Field } from '../types'

type Props = {
  shouldFocus: boolean
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
}

const CardName = ({ onBlur, shouldFocus }: Props) => {
  const translate = useTranslate('credit_card_add.name')
  const {
    register,
    setFocus,
    formState: { errors },
  } = useFormContext<FormModel>()
  const validationMessage = useFormValidationMessage(errors, 'credit_card_add')

  useEffect(() => {
    if (!shouldFocus) return

    setFocus(Field.Name)
  }, [setFocus, shouldFocus])

  function validate(value: string) {
    if (!FULL_NAME.test(value)) {
      return translate('errors.format')
    }

    return undefined
  }

  return (
    <InputText
      {...register(Field.Name, {
        required: true,
        validate,
      })}
      title={translate('title')}
      placeholder={translate('placeholder')}
      styling={InputText.Styling.Tight}
      validation={renderValidation(validationMessage(Field.Name))}
      onBlur={onBlur}
    />
  )
}

export default CardName
