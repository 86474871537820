'use client'

import { useState, FocusEvent, ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { Icon, InputText } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { renderValidation } from 'components/Input'
import { CreditCardConfigurationModel } from 'types/models'
import { numbersOnly } from 'libs/utils/number'

import { FormModel, Field } from '../types'

type Props = {
  config?: CreditCardConfigurationModel
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
  onInfoIconClick: () => void
}

const CardCVC = ({ config, onBlur, onInfoIconClick }: Props) => {
  const translate = useTranslate('credit_card_add.cvc')

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormModel>()

  const validationMessage = useFormValidationMessage(errors, 'credit_card_add')

  const [isInfoTextVisible, setIsInfoTextVisible] = useState(false)

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target

    setValue(Field.CVC, numbersOnly(value))
  }

  function showInfoText() {
    setIsInfoTextVisible(true)
    onInfoIconClick()
  }

  function hideInfoText() {
    setIsInfoTextVisible(false)
  }

  function renderInfoIcon() {
    return (
      <button
        type="button"
        className="u-flexbox u-align-items-center u-cursor-pointer"
        onClick={isInfoTextVisible ? hideInfoText : showInfoText}
        data-testid="cvc-info-circle"
        // TODO: Add aria-label
      >
        <Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel4} />
      </button>
    )
  }

  function renderInfoText() {
    if (!isInfoTextVisible) return null

    const key = config?.cvvRequired ? 'info_text' : 'info_text_optional'

    return renderValidation(translate(key), 'muted')
  }

  function renderErrorMessage() {
    return renderValidation(validationMessage(Field.CVC))
  }

  if (config?.cvvMaxLength === 0) return null

  return (
    <InputText
      {...register(Field.CVC, {
        required: config?.cvvRequired,
        minLength: config?.cvvMinLength,
        maxLength: config?.cvvMaxLength,
      })}
      title={translate('title')}
      placeholder={translate('placeholder')}
      onChange={handleChange}
      onBlur={onBlur}
      inputMode={InputText.InputMode.Numeric}
      maxLength={config?.cvvMaxLength}
      styling={InputText.Styling.Tight}
      validation={renderErrorMessage() || renderInfoText()}
      suffix={renderInfoIcon()}
    />
  )
}

export default CardCVC
