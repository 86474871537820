import { useEffect } from 'react'

import useTracking from 'hooks/useTracking'

import { getLocalStorageItem, removeLocalStorageItem } from 'libs/utils/localStorage'
import { creditCardAddModalLoadDurationEvent } from 'libs/common/event-tracker/events'

import { CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY } from 'constants/checkout'

const isCreditCardAddLoadTimeTrackingDetails = (details: { loadTimeStartStamp: number }) => {
  return 'loadTimeStartStamp' in details
}

const getModalLoadTime = (loadTimeStartStampMS: number) => {
  const loadTimeEndStampMS = Date.now()

  return loadTimeEndStampMS - loadTimeStartStampMS
}

const getLoadTimeStartTrackingDetails = () => {
  const trackingDetailsString = getLocalStorageItem(
    CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
  )

  if (!trackingDetailsString) return null

  removeLocalStorageItem(CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY)

  const trackingDetails = JSON.parse(trackingDetailsString)

  if (isCreditCardAddLoadTimeTrackingDetails(trackingDetails))
    return {
      loadTimeStartStamp: trackingDetails.loadTimeStartStamp,
    }

  return null
}

const useCreditCardAddModalLoadDurationTracking = ({
  checkoutId,
  isCreditCardModalUiLoaded,
}: {
  checkoutId: string | undefined
  isCreditCardModalUiLoaded: boolean
}) => {
  const { track } = useTracking()

  useEffect(() => {
    if (!isCreditCardModalUiLoaded || !checkoutId) return

    const trackingDetails = getLoadTimeStartTrackingDetails()

    if (!trackingDetails) return

    const modalLoadTime = getModalLoadTime(trackingDetails.loadTimeStartStamp)

    track(
      creditCardAddModalLoadDurationEvent({
        checkoutId,
        duration: modalLoadTime,
      }),
    )
  }, [track, checkoutId, isCreditCardModalUiLoaded])
}

export default useCreditCardAddModalLoadDurationTracking
